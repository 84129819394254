import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: 335px;
  align-items: center;
  flex-shrink: 0;
`;

export const Square = styled.label`
  cursor: pointer;
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  background-color: ${props =>
    props.active ? '#7DBEC8' : 'white'};
  border-radius: 2px;
  border: ${props =>
    props.active ? `1px solid #7DBEC8` : '1px solid #A8A9AF'};
  margin-right: 9px;
  position: relative;
  img {
    opacity: ${props => (props.active ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: contain;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -45%);
  }
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #464648;
`;
