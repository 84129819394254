import styled from 'styled-components';

export const SubmitButton = styled.div`
  background-color: #E1CFCD;
  width: calc(100% - 40px);
  height: 60px;
  cursor: pointer;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.348vw;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-transform: uppercase;
  margin-bottom: 40px;
`;

export const Footer = styled.pre`
  background-color: #F4F5FA;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 4.348vw;
  color: #000000;
  letter-spacing: 0;
  line-height: 9.179vw;
  padding: 23.19vw 4.831vw;
  margin: 0;
`;

export const Container = styled.div`
  background: #FFFFFF;
`;

export const Image = styled.img`
  width: 100%;
  height: 235px;
  object-fit: cover;
`;

export const Heading = styled.div`
  height: 235px;
  background-color: white;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 9.179vw;
  color: #464855;
  letter-spacing: 0;
  padding-left: 7.729vw;
  padding-top: 5.314vw;
  text-transform: uppercase;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 400;
  font-size: 4.831vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 11.59vw;
  padding-left: 7.729vw;
  width: 63.04vw;
`;

export const Body = styled.div`
padding-bottom: 20.53vw;
display: flex;
flex-direction: column;
margin: 20px;
> div {
  margin-bottom: 20px;
}
`;