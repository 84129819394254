import styled from 'styled-components';

export const Container = styled.form`
`;

export const Heading = styled.div`
   height: 610px;
   width: 100%;
   display: flex;
   position: relative;
   z-index: -1;
`;

export const SideInfo = styled.div`
  margin-left:43px;
  margin-top:43px;
  width: 372px;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  line-height: 38px;
`;

export const TextHeading = styled.div`
  position: absolute;
  margin-left: 215px;
  margin-top: 200px;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 68px;
  color: #124C56;
  letter-spacing: 0;
  text-transform: uppercase;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 28px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 48px;
  span {
    font-weight: 700;
  }
`;

export const PasswordText = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  span {
    text-decoration: underline;
    color: #6B969D;
  }
`;