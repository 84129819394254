import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { createBuyerAccount } from '../../../actions/userActions';
import Input from '../../ThemedInput';
import Button from '../../Button2';
import Checkbox from '../../Checkbox';
import ThemedDropdown from '../../ThemedDropdown';
import { STATE_OPTIONS } from '../../../constants';
import {
  Container, Heading, Title, SubTitle, Image, Body, Footer,
} from './styled';
import background_image from './background_image.png';

function CreateAccount({ createBuyerAccount }) {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [street, setStreet] = useState(null);
  const [city, setCity] = useState(null);
  const [_state, _setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  return (
    <Container>
      <Heading>
        <div style={{
          height: 235, position: 'absolute', backgroundImage: 'linear-gradient(-45deg, rgba(248,220,220,0.20) 0%, rgba(255,246,245,0.91) 50%)',
        }}>
          <Title>Create Your Account</Title>
          <SubTitle><span style={{ fontFamily: 'sofia-pro', fontWeight: 700 }}>For Buyers:</span> Sign up to buy your dream wedding gown.</SubTitle>
        </div>
        <Image src={background_image} />
      </Heading>
      <Body>
        <Input value={firstName} update={setFirstName} label="First Name" />
        <Input value={lastName} update={setLastName} label="Last Name" />
        <Input value={street} update={setStreet} label="Address" />
        <Input value={city} update={setCity} label="City" />
        <ThemedDropdown options={STATE_OPTIONS} value={_state} update={_setState} label="State" />
        <Input value={zip} update={setZip} label="Zip Code" />
        <Input type="phoneNumber" value={phoneNumber} update={setPhoneNumber} label="Phone Number" />
        <Input value={email} update={setEmail} label="Email" />
        <Input type='password' value={password} update={setPassword} label="Create Password" />
        <Checkbox
          active={agreeToTerms}
          update={setAgreeToTerms}
          label={<div style={{
            fontFamily: 'sofia-pro',
            fontWeight: 400,
            fontSize: 18,
            color: '#000000',
          }}>I agree to the site’s <span onClick={() => navigate('/terms-of-service')} style={{
              textDecoration: 'underline',
              color: '#6B969D',
            }}>terms & conditions.</span></div>} />
      </Body>
      <Button style={{ margin: 'auto', marginBottom: 40 }} onClick={() => {
        if (!agreeToTerms) {
          return alert('You must agree to the terms before making an account.');
        } else {
          createBuyerAccount({
            firstName,
            lastName,
            street,
            city,
            zip,
            state: _state,
            phoneNumber,
            email,
            password,
          });
        }
      }}>Create Account</Button>
      <Footer>
        {`Fill out the form to create an account.
• Get a FREE account in less than 1 minute
• Access your personalized fitting room
• Control how and when we email you.
_
Have Questions?
We have the answers for you!`}
      </Footer>
    </Container>
  );
}

const mapDispatchToProps = { createBuyerAccount };

export default connect(null, mapDispatchToProps)(CreateAccount);
