import React from 'react';
import {
  Container, Square, Label,
} from './styled';
import CheckIcon from './CheckIcon.svg';

function Checkbox({
  label, style, active, update, ...other
}) {
  return (
    <Container style={{ ...style }}>
      <Square {...other} onClick={() => update(!active)} active={active}>
        <img src={CheckIcon} />
      </Square>
      <Label>{label}</Label>
    </Container>
  );
}

export default Checkbox;
