import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { createBuyerAccount } from '../../../actions/userActions';
import Image from '../../ImageBasic';
import Button from '../../Button1';
import Row from '../../Row';
import Column from '../../Column';
import Input from '../../ThemedInput';
import Checkbox from '../../Checkbox';
import { STATE_OPTIONS } from '../../../constants';
import ThemedDropdown from '../../ThemedDropdown';
import {
  Container, Heading, TextHeading,
  Title, SubTitle, PasswordText,
} from './styled';
import BackgroundImage from './background.png';

function CreateAccount({ createBuyerAccount }) {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [street1, setStreet1] = useState(null);
  const [street2, setStreet2] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(null);

  return (
    <Container onSubmit={(e) => {
      e.preventDefault();

      if (agreeToTerms) {
        createBuyerAccount({
          firstName, lastName, street1,
          street2, city, state,
          zip, phoneNumber, email,
          password,
        });
      } else {
        alert('You must accept the terms to continue');
      }
      return false;
    }}>
      <Heading>
        <Image style={{
          objectFit: 'cover', width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 0,
        }} src={BackgroundImage} />
        <TextHeading>
          <Title>Create Your Account</Title>
          <SubTitle><span>For Buyers:</span> Sign up to buy your dream wedding gown.</SubTitle>
        </TextHeading>
      </Heading>
      <Row style={{
        border: '1px solid #7DBEC8', width: 685 , marginLeft: 217,
        marginTop: -125, backgroundColor: 'white', marginBottom: 45,
      }}>
        <Column style={{ margin: 45, width: '100%' }}>
          <Row style={{ width: '100%' }}>
            <Input required={true} style={{ marginRight: 30 }} label="First Name" value={firstName} update={setFirstName} />
            <Input required={true} label="Last Name" value={lastName} update={setLastName} />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input required={true} label="Address Line 1" value={street1} update={setStreet1} />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input label="Address Line 2" value={street2} update={setStreet2} />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input required={true} style={{ flex: .5, marginRight: 30 }} label="City" value={city} update={setCity} />
            <ThemedDropdown options={STATE_OPTIONS} required={true} style={{ flex: .1, marginRight: 30 }} label="State" value={state} update={setState} />
            <Input required={true} style={{ flex: .4 }} label="Zip" value={zip} update={setZip} />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input type="phoneNumber" required={true} label="Phone Number" value={phoneNumber} update={setPhoneNumber} />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input required={true} label="Email Address" value={email} update={setEmail} />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input required={true} type="password" label="Create Password" value={password} update={setPassword} />
          </Row>
          <Row style={{ marginTop: 21 }}>
            <Checkbox active={agreeToTerms} update={setAgreeToTerms} label={<PasswordText>I agree to the site's <span onClick={() => navigate('/terms-of-service')}>terms & conditions</span></PasswordText>} />
          </Row>
          <Button style={{ marginTop: 21 }}>Create My Account</Button>
        </Column>
      </Row>
    </Container>
  );
}

const mapDispatchToProps = { createBuyerAccount };

export default connect(null, mapDispatchToProps)(CreateAccount);


{/* <SideInfo>
Fill out the form to create an account.
<br />
• Get a FREE account in less than 1 minute
<br />
• Access your personalized fitting room
<br />
• Control how and when we email you.
<br />
_
<br />
Have Questions?
<br />
We have the answers for you!
</SideInfo> */}