import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import CreateAccountMobile from '../components/Mobile/CreateAccount';
import CreateAccountDesktop from '../components/Desktop/CreateAccount';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';

function CreateAccount({ setMetaTags }) {
  useEffect(() => {
    const description = 'Create an account to find the dress of your dreams';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely - Create Account`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);
  return (
    <Layout
      mobile={<CreateAccountMobile />}
      desktop={<CreateAccountDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(CreateAccount);